import Button from '@core-ui/button';
import { TableCell, TableRow } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AmountFormatter } from '@/app/types/common';
import { CostClientResponseSchemaFlat } from '@/generated';
import { makeUrlToDocumentsFromExpensesClientArticles } from '@/pages/analytics/AnalyticsInner/utils';
import { ArrowSquareOut } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';

interface IProps {
  costArticle: CostClientResponseSchemaFlat;
  boatId: Nullable<number>;
  dateFrom: Nullable<string>;
  dateTo: Nullable<string>;
  amountFormatter: AmountFormatter;
}

const AnalyticsExpensesClientArticlesGeneralTableRow = (props: IProps) => {
  const { costArticle, boatId, dateFrom, dateTo, amountFormatter } = props;

  const history = useHistory();
  const styles = useStyles();

  const handleOpenInLedger = () => {
    history.push(makeUrlToDocumentsFromExpensesClientArticles(boatId, costArticle, dateFrom, dateTo));
  };

  return (
    <TableRow>
      <TableCell sx={styles.nameCell}>
        <Grid item container justifyContent="space-between">
          <Typography>{costArticle.name}</Typography>

          <Tooltip title={<FormattedMessage id="tooltip.open_relevant_documents_in_ledger" />} placement="top" arrow>
            <Button
              variant="text"
              // TODO: compact, size="small" и minimalSpacing не работают и
              //  не позволяют убрать паддинги иконки, нужно фиксить это в корке
              sx={styles.openInLedgerButton}
              onClick={handleOpenInLedger}
              icon={
                <SvgIcon fontSize="small">
                  <ArrowSquareOut weight="bold" />
                </SvgIcon>
              }
            />
          </Tooltip>
        </Grid>
      </TableCell>

      <TableCell align="right">
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(costArticle.total_price)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default AnalyticsExpensesClientArticlesGeneralTableRow;
