import { useAppSelector } from '@/hooks/useAppSelector';
import {
  PLAN_FACT_TABLE_HEADER_PREFIX,
  PLAN_FACT_TABLE_NAME_HEADER_ID,
} from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/consts';
import { HeaderCell, TableCell, TableRow } from '@core-ui/table';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'components/Intl';
import React from 'react';
import selector from './selector';
import useStyles from './styles';

const AnalyticsPlanFactClientArticlesTableHeader = () => {
  const { monthHeaders, showRub } = useAppSelector(selector);
  const styles = useStyles();

  const currencyLabelId = showRub ? 'label.rub' : 'label.eur';
  const headerLabelPrefix = <FormattedMessage id={currencyLabelId} />;

  return (
    <TableHead>
      <TableRow>
        <HeaderCell sx={styles.nameCell} shadow="dynamicRight" id={PLAN_FACT_TABLE_NAME_HEADER_ID}>
          <FormattedMessage id="table.header.name" />
        </HeaderCell>

        {monthHeaders.map((header) => (
          <HeaderCell
            key={header}
            colSpan={3}
            align="right"
            sx={styles.monthCell}
            id={PLAN_FACT_TABLE_HEADER_PREFIX + header}
          >
            <Box>
              ({headerLabelPrefix}) {header}
            </Box>
          </HeaderCell>
        ))}
      </TableRow>

      <TableRow>
        <TableCell sx={styles.placeholderCell} shadow="dynamicRight" />

        {monthHeaders.map((month) => (
          <>
            <TableCell key={`${month}-plan`} align="right" sx={styles.planFactDiffCell}>
              <Typography fontWeight="semiBold">
                <FormattedMessage id="label.plan" />
              </Typography>
            </TableCell>

            <TableCell key={`${month}-fact`} align="right" sx={styles.planFactDiffCell}>
              <Typography fontWeight="semiBold">
                <FormattedMessage id="label.fact" />
              </Typography>
            </TableCell>

            <TableCell key={`${month}-diff`} align="right" sx={styles.planFactDiffCell}>
              <Typography fontWeight="semiBold">
                <FormattedMessage id="label.diff" />
              </Typography>
            </TableCell>
          </>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default AnalyticsPlanFactClientArticlesTableHeader;
