import Typography from '@mui/material/Typography';
import { AmountFormatter } from '@/app/types/common';

interface IProps {
  amount: number;
  amountFormatter: AmountFormatter;
}

const AnalyticsExpensesClientArticlesMonthTableCell = (props: IProps) => {
  const { amount, amountFormatter } = props;

  return <Typography fontFamily="mono">{amountFormatter(amount)}</Typography>;
};

export default AnalyticsExpensesClientArticlesMonthTableCell;
