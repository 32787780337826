import { createSelector } from 'reselect';
import { formatTime, getTimestampInMs } from '@/app/utils/dates';
import { getAmountFormatter } from '@/dictionary/selector';
import {
  getAnalyticsFiltersSelectedMonths,
  getCurrentYearSelected,
} from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { getAnalyticsPlanFactClientArticlesValue } from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactRoot/selector';

export default createSelector(
  [
    getAnalyticsPlanFactClientArticlesValue,
    getAnalyticsFiltersSelectedMonths,
    getAmountFormatter,
    getCurrentYearSelected,
  ],
  (planFactValue, selectedMonths, amountFormatted, isCurrentYearSelected) => {
    const items = planFactValue?.plan_fact_by_month?.map((item) => ({
      ...item,
      name: formatTime(getTimestampInMs(item.start_month), { dateFormat: isCurrentYearSelected ? 'MMMM' : 'MMMM-YY' }),
    }));

    return {
      items,
      selectedMonths,
      amountFormatted,
    };
  }
);
