import { useQuery } from '@core-ui/redux-router';
import Grid from '@mui/material/Grid';
import { ANALYTICS_EXPENSES_VIEW, ANALYTICS_GRAPH_HEIGHT } from '@/pages/analytics/AnalyticsInner/consts';
import { AnalyticsQueryParams } from '@/pages/analytics/AnalyticsInner/types';
import React from 'react';
import {
  AnalyticsExpensesMyoceanArticlesGeneralGraph,
  AnalyticsExpensesMyoceanArticlesGeneralTable,
  AnalyticsExpensesMyoceanArticlesMonthGraph,
  AnalyticsExpensesMyoceanArticlesMonthTable,
} from './components';

const AnalyticsExpensesMyoceanArticles = () => {
  const query = useQuery<AnalyticsQueryParams>();
  const isGeneralViewSelected = query.view === ANALYTICS_EXPENSES_VIEW.GENERAL;

  return (
    <>
      <Grid item container wrap="nowrap" marginY={6} height={ANALYTICS_GRAPH_HEIGHT}>
        {isGeneralViewSelected ? (
          <AnalyticsExpensesMyoceanArticlesGeneralGraph />
        ) : (
          <AnalyticsExpensesMyoceanArticlesMonthGraph />
        )}
      </Grid>

      <Grid item width="100%" height="100%">
        {isGeneralViewSelected ? (
          <AnalyticsExpensesMyoceanArticlesGeneralTable />
        ) : (
          <AnalyticsExpensesMyoceanArticlesMonthTable />
        )}
      </Grid>
    </>
  );
};

export default AnalyticsExpensesMyoceanArticles;
