import clientArticles, {
  IAnalyticsExpensesClientArticlesState,
} from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesClientArticles/reducer';
import { combineReducers } from 'redux';
import myoceanArticles, { IAnalyticsExpensesMyoceanArticlesState } from './AnalyticsExpensesMyoceanArticles/reducer';

export interface IAnalyticsExpensesState {
  myoceanArticles: IAnalyticsExpensesMyoceanArticlesState;
  clientArticles: IAnalyticsExpensesClientArticlesState;
}

export default combineReducers({
  myoceanArticles,
  clientArticles,
});
