import {
  PLAN_FACT_TABLE_MIN_MONTH_CELL_WIDTH,
  PLAN_FACT_TABLE_MIN_NAME_CELL_WIDTH,
} from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/components/AnalyticsPlanFactClientArticlesTable/consts';
import {
  PLAN_FACT_TABLE_HEADER_PREFIX,
  PLAN_FACT_TABLE_NAME_HEADER_ID,
} from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/consts';
import isNil from 'lodash-es/isNil';
import {
  BAR_HEIGHT,
  BAR_OFFSET_FROM_MONTH_CENTRAL,
  BAR_WIDTH,
  MONTH_GAP,
  RADIUS,
  X_RADIUS,
  Y_AXIS_WIDTH,
  Y_RADIUS,
} from './consts';
import { IPlanFactBar } from './types';

const getFixedTableColumnsWidth = (): number => {
  let nameColumnHeaderWidth = PLAN_FACT_TABLE_MIN_NAME_CELL_WIDTH;

  const nameColumnHeader = document.getElementById(PLAN_FACT_TABLE_NAME_HEADER_ID);

  if (nameColumnHeader) {
    nameColumnHeaderWidth = nameColumnHeader.getBoundingClientRect().width;
  }

  return nameColumnHeaderWidth;
};

const getMediumMonthCellWidth = (selectedMonths: string[]): number => {
  const monthIds = selectedMonths.map((month) => `${PLAN_FACT_TABLE_HEADER_PREFIX}${month}`);

  const monthHeadersTotalWidth = monthIds.reduce<number>((totalWidth, id) => {
    const header = document.getElementById(id);

    if (!header) {
      return totalWidth + PLAN_FACT_TABLE_MIN_MONTH_CELL_WIDTH;
    }

    return totalWidth + header.getBoundingClientRect().width;
  }, 0);

  return monthHeadersTotalWidth / selectedMonths.length;
};

export const getMonthWidth = (selectedMonths: string[]): number => {
  const fixedColumnsWidth = getFixedTableColumnsWidth();
  const mediumMonthColumnWidth = getMediumMonthCellWidth(selectedMonths);

  const fixedColumnsCompensationWidth = (fixedColumnsWidth - Y_AXIS_WIDTH) / selectedMonths.length - MONTH_GAP;

  return fixedColumnsCompensationWidth + mediumMonthColumnWidth;
};

export const getMaxBarHeight = (bars: IPlanFactBar[]): number => {
  return bars.reduce<number>((currentMax, bar) => {
    if (isNil(bar.budget) && !isNil(bar.fact)) {
      return Math.max(currentMax, bar.fact);
    }

    if (isNil(bar.fact) && !isNil(bar.budget)) {
      return Math.max(currentMax, bar.budget);
    }

    if (!isNil(bar.budget) && !isNil(bar.fact)) {
      return Math.max(currentMax, bar.budget, bar.fact);
    }

    return currentMax;
  }, 0);
};

export const getPlanFactBarPath = (
  barType: 'budget' | 'fact',
  barValue: number,
  xScaleOffset: number,
  yScaleOffset: number,
  xMonthSubScaleBandwidth: number
): string => {
  // если ноль, то столбец не рисуем вообще
  if (barValue === 0) {
    return '';
  }

  const yHeight = BAR_HEIGHT - yScaleOffset;
  let xOffset: number;

  if (barType === 'budget') {
    xOffset = xScaleOffset + xMonthSubScaleBandwidth - BAR_WIDTH - BAR_OFFSET_FROM_MONTH_CENTRAL;
  } else {
    xOffset = xScaleOffset + xMonthSubScaleBandwidth + BAR_OFFSET_FROM_MONTH_CENTRAL;
  }

  // если значение есть, но рассчитанная из неё высота по оси Y меньше радиуса скругления столбца,
  // то рисуем только верхнюю часть столбца высотой 8 пикселей
  if (yHeight <= RADIUS) {
    return `
            M${xOffset},${BAR_HEIGHT}
            a${X_RADIUS},${Y_RADIUS} 0 0 1 ${X_RADIUS},${-Y_RADIUS}
            h${BAR_WIDTH - 2 * X_RADIUS}
            a${X_RADIUS},${Y_RADIUS} 0 0 1 ${X_RADIUS},${Y_RADIUS}
          `;
  }

  return `
          M${xOffset},${yScaleOffset + Y_RADIUS}
          a${X_RADIUS},${Y_RADIUS} 0 0 1 ${X_RADIUS},${-Y_RADIUS}
          h${BAR_WIDTH - 2 * X_RADIUS}
          a${X_RADIUS},${Y_RADIUS} 0 0 1 ${X_RADIUS},${Y_RADIUS}
          v${BAR_HEIGHT - yScaleOffset - Y_RADIUS}
          h${-BAR_WIDTH}Z
      `;
};
