import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { ICostTypeEnum } from '@/app/consts/app';
import { getEndOfCurrentYearInUnix, getStartOfCurrentYearInUnix } from '@/app/utils/dates';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export interface IAnalyticsFiltersState {
  dateFrom: Nullable<string>;
  dateTo: Nullable<string>;
  variability: Nullable<ICostTypeEnum>;
  costCenters: Nullable<number[]>;
}

const defaultState = Immutable<IAnalyticsFiltersState>({
  dateFrom: String(getStartOfCurrentYearInUnix()),
  dateTo: String(getEndOfCurrentYearInUnix()),
  variability: null,
  costCenters: null,
});

export default handleActions({}, reducer<typeof defaultState>(actions.reducerName, defaultState));
