import State from '@/app/types/state';
import { createSelector } from 'reselect';

export const getAnalyticsClientArticlesList = (state: State) => state.analytics.analyticsInner.expenses.clientArticles;
export const getAnalyticsClientArticlesListValues = (state: State) =>
  state.analytics.analyticsInner.expenses.clientArticles.value;

export const getAnalyticsClientArticlesTotal = createSelector(
  [getAnalyticsClientArticlesList],
  (analyticsCostArticlesList) => {
    return analyticsCostArticlesList.value?.reduce((acc, curr) => acc + (curr.total_price ?? 0), 0) ?? 0;
  }
);
