import TableBody from '@mui/material/TableBody';
import { useAppSelector } from '@/hooks/useAppSelector';
import React from 'react';
import { AnalyticsExpensesMyoceanArticlesMonthTableRow } from '..';
import selector from './selector';

const AnalyticsExpensesMyoceanArticlesMonthTableBody = () => {
  const { items, boatId, selectedMonths, filters, amountFormatter } = useAppSelector(selector);

  return (
    <TableBody>
      {items?.map((costArticle, index) => (
        <AnalyticsExpensesMyoceanArticlesMonthTableRow
          key={`${costArticle.id}.${costArticle.ca_param}.${costArticle.variability}.${costArticle.currency_id}`}
          costArticle={costArticle}
          monthInterval={selectedMonths}
          boatId={boatId}
          dateFrom={filters.dateFrom}
          dateTo={filters.dateTo}
          isFirstRow={index === 0}
          amountFormatter={amountFormatter}
        />
      ))}
    </TableBody>
  );
};

export default AnalyticsExpensesMyoceanArticlesMonthTableBody;
