import { COLOURS, HEIGHT_SIZE } from '@core-ui/styles';

export default () => ({
  container: {
    overflow: 'hidden',
    height: `calc(100vh - (${HEIGHT_SIZE.headerHeight}px))`,
    width: 'auto',
  },

  routesContainer: {
    background: COLOURS.Coconut.Solid[100],
  },

  fallback: {
    margin: 'auto',
  },
});
