import { getList, resetState, setList, setLoading } from '@core-ui/reducers_handlers';
import { ICostClientResponseSchemaFlat } from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesClientArticles/types';

export const reducerName = 'analytics.expenses.client_articles.list';

export const getClientArticlesAnalyticsExpensesList = getList(reducerName);
export const setClientArticlesAnalyticsExpensesList = setList<ICostClientResponseSchemaFlat>(reducerName);

export const setClientArticlesAnalyticsExpensesLoading = setLoading(reducerName);

export const resetClientArticlesAnalyticsExpensesList = resetState(reducerName);
