import { getCostArticleMappingEnabled } from '@/app/selectors';
import State from '@/app/types/state';
import { NoDataContainerSelector } from '@/components/NoDataContainer/NoDataContainer';
import { createSelector } from 'reselect';

export const getAnalyticsPlanFactClientArticles = (state: State) =>
  state.analytics.analyticsInner.planFact.clientArticles;
export const getAnalyticsPlanFactClientArticlesValue = (state: State) =>
  state.analytics.analyticsInner.planFact.clientArticles.value;

// TODO: добавить селектор getAnalyticsPlanFactMyoceanArticles когда будем делать план-факт для наших артиклов
export const noDataSelector: NoDataContainerSelector = createSelector(
  [getCostArticleMappingEnabled, getAnalyticsPlanFactClientArticles],
  (mappingEnabled, analyticsPlanFactClientArticles) => ({
    loading: analyticsPlanFactClientArticles.loading,
    loaded: analyticsPlanFactClientArticles.loaded,
    hasData: analyticsPlanFactClientArticles.hasData,
    error: analyticsPlanFactClientArticles.error,
  })
);
