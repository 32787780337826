import { getCostArticleMappingEnabled, getUserCostArticlesMappingLoading } from '@/app/selectors';
import { createSelector } from 'reselect';

export default createSelector(
  [getCostArticleMappingEnabled, getUserCostArticlesMappingLoading],
  (costArticlesMappingEnabled, userCostArticlesMappingLoading) => ({
    costArticlesMappingEnabled,
    loading: userCostArticlesMappingLoading,
  })
);
