import analyticsInner, { IAnalyticsInnerState } from '@/pages/analytics/AnalyticsInner/reducer';
import { combineReducers } from 'redux';

export interface IAnalyticsState {
  analyticsInner: IAnalyticsInnerState;
}

export default combineReducers({
  analyticsInner,
});
