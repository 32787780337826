import { COLOURS } from '@core-ui/styles';
import Tabs, { ITab } from '@core-ui/tabs';
import { buildURL } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import { IRouteParams, ROUTES } from '@/app/consts/routes';
import { vehicleIdSelector } from '@/app/selectors';
import { useAppSelector } from '@/hooks/useAppSelector';
import {
  ANALYTICS_EXPENSES_VIEW,
  ANALYTICS_INNER_TABS,
  ANALYTICS_VIEW_QUERY_KEY,
} from '@/pages/analytics/AnalyticsInner/consts';
import { ANALYTICS_TABS } from '@/pages/analytics/consts';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import { useParams } from 'react-router';

const containerStyles: SxProps<Theme> = {
  paddingLeft: 6,
  paddingTop: 6,
  background: COLOURS.Coconut.Solid[50],
};

const AnalyticsInnerTabs = () => {
  const boatId = useAppSelector(vehicleIdSelector);
  const params = useParams<IRouteParams>();

  const tabs: ITab[] = [
    {
      value: ANALYTICS_INNER_TABS.EXPENSES,
      label: <FormattedMessage id="analytics.tabs.expenses" />,
      dataTestId: 'analytics_page-tabs-expenses',
      to: buildURL(`/${boatId}/${ROUTES.ANALYTICS}/${ANALYTICS_TABS.ANALYTICS}/${ANALYTICS_INNER_TABS.EXPENSES}`, {
        [ANALYTICS_VIEW_QUERY_KEY]: ANALYTICS_EXPENSES_VIEW.MONTH,
      }),
    },
    {
      value: ANALYTICS_INNER_TABS.PLAN_FACT,
      label: <FormattedMessage id="analytics.tabs.plan_vs_fact" />,
      dataTestId: 'analytics_page-tabs-plan_fact',
      to: buildURL(`/${boatId}/${ROUTES.ANALYTICS}/${ANALYTICS_TABS.ANALYTICS}/${ANALYTICS_INNER_TABS.PLAN_FACT}`),
    },
  ];

  return (
    <Grid item container sx={containerStyles}>
      <Tabs size="small" tabs={tabs} active={params.analyticsInnerTab} />
    </Grid>
  );
};

export default AnalyticsInnerTabs;
