import clientArticles, {
  IAnalyticsPlanFactClientArticles,
} from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/reducer';
import { combineReducers } from 'redux';

export interface IAnalyticsPlanFact {
  clientArticles: IAnalyticsPlanFactClientArticles;
}

export default combineReducers({
  clientArticles,
});
