import Grid from '@mui/material/Grid';
import {
  AnalyticsPlanFactClientArticlesGraph,
  AnalyticsPlanFactClientArticlesTable,
} from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/components';
import { ANALYTICS_GRAPH_HEIGHT } from '@/pages/analytics/AnalyticsInner/consts';
import React from 'react';

const AnalyticsPlanFactClientArticles = () => {
  return (
    <>
      <Grid item container wrap="nowrap" marginY={6} height={ANALYTICS_GRAPH_HEIGHT}>
        <AnalyticsPlanFactClientArticlesGraph />
      </Grid>

      <Grid item width="100%" height="100%">
        <AnalyticsPlanFactClientArticlesTable />
      </Grid>
    </>
  );
};

export default AnalyticsPlanFactClientArticles;
