import Button from '@core-ui/button';
import { TableCell, TableRow } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ArrowSquareOut } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useHistory } from 'react-router-dom';
import { AmountFormatter } from '@/app/types/common';
import { ICostClientResponseSchemaFlat } from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesClientArticles/types';
import { makeUrlToDocumentsFromExpensesClientArticles } from '@/pages/analytics/AnalyticsInner/utils';
import { AnalyticsExpensesClientArticlesMonthTableCell } from '..';
import useStyles from './styles';

interface IProps {
  costArticle: ICostClientResponseSchemaFlat;
  boatId: Nullable<number>;
  dateFrom: Nullable<string>;
  dateTo: Nullable<string>;
  monthInterval: string[];
  amountFormatter: AmountFormatter;
}

const AnalyticsExpensesClientArticlesMonthTableRow: FC<IProps> = (props) => {
  const { costArticle, boatId, dateFrom, dateTo, monthInterval, amountFormatter } = props;

  const history = useHistory();
  const styles = useStyles();

  const handleOpenInLedger = () => {
    history.push(makeUrlToDocumentsFromExpensesClientArticles(boatId, costArticle, dateFrom, dateTo));
  };

  return (
    <TableRow sx={styles.row}>
      <TableCell sx={styles.nameCell}>
        <Grid item container justifyContent="space-between">
          <Typography>{costArticle.name}</Typography>

          <Tooltip title={<FormattedMessage id="tooltip.open_relevant_documents_in_ledger" />} placement="top" arrow>
            <Button
              onClick={handleOpenInLedger}
              variant="text"
              // TODO: compact, size="small" и minimalSpacing не работают и
              //  не позволяют убрать паддинги иконки, нужно фиксить это в корке
              sx={styles.openInLedgerButton}
              icon={
                <SvgIcon fontSize="small">
                  <ArrowSquareOut weight="bold" />
                </SvgIcon>
              }
            />
          </Tooltip>
        </Grid>
      </TableCell>

      <TableCell align="right" shadow="dynamicRight" sx={styles.totalCell}>
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(costArticle.total_price)}
        </Typography>
      </TableCell>

      {monthInterval.map((month, index) => (
        <TableCell key={month} align="right">
          <AnalyticsExpensesClientArticlesMonthTableCell
            amount={costArticle.formattedPricesPerMonth[index]?.total ?? 0}
            amountFormatter={amountFormatter}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

export default AnalyticsExpensesClientArticlesMonthTableRow;
