import State from '@/app/types/state';
import { dateToUTC, formatTime, fromUnixTime } from '@/app/utils/dates';
import { getCostCentersDictOptions } from '@/dictionary/selector';
import { createSelector } from 'reselect';

export const getAnalyticsFilters = (state: State) => state.analytics.analyticsInner.filters;

export const getAnalyticsFiltersSelectedMonths = createSelector([getAnalyticsFilters], (filters) => {
  const months = [];

  if (filters.dateFrom && filters.dateTo) {
    const dateFrom = fromUnixTime(Number(filters.dateFrom));
    const dateTo = fromUnixTime(Number(filters.dateTo));

    while (dateFrom <= dateTo) {
      months.push(formatTime(dateFrom, { dateFormat: 'MMMM-YY' }));
      dateFrom.setMonth(dateFrom.getMonth() + 1);
    }
  }

  return months;
});

export const getCurrentYearSelected = createSelector([getAnalyticsFilters], (filters) => {
  if (filters.dateFrom && filters.dateTo) {
    const dateFrom = dateToUTC(fromUnixTime(Number(filters.dateFrom)));
    const dateTo = dateToUTC(fromUnixTime(Number(filters.dateTo)));
    const currentDate = dateToUTC(new Date());

    return dateFrom.getFullYear() === currentDate.getFullYear() && dateTo.getFullYear() === currentDate.getFullYear();
  }

  return false;
});

export const getAnalyticsFiltersApplied = createSelector([getAnalyticsFilters], (filters) =>
  Object.values(filters).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return Boolean(value);
  })
);

export default createSelector([getAnalyticsFilters, getCostCentersDictOptions], (filters, costCentersOptions) => ({
  filters,
  costCentersOptions,
}));
