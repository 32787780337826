import { shouldShowRub } from '@/app/selectors';
import { getAmountFormatter } from '@/dictionary/selector';
import { getAnalyticsClientArticlesTotal } from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesClientArticles/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getAnalyticsClientArticlesTotal, getAmountFormatter, shouldShowRub],
  (analyticsTotal, amountFormatter, showRub) => {
    const formattedTotal = amountFormatter(analyticsTotal);

    return {
      formattedTotal,
      showRub,
    };
  }
);
