import { createSelector } from 'reselect';
import { vehicleIdSelector } from '@/app/selectors';
import { getAmountFormatter } from '@/dictionary/selector';
import { getAnalyticsMyoceanArticlesListValue } from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/selector';
import { getAnalyticsFilters } from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';

export default createSelector(
  [vehicleIdSelector, getAnalyticsFilters, getAnalyticsMyoceanArticlesListValue, getAmountFormatter],
  (boatId, filters, items, amountFormatter) => ({
    boatId,
    filters,
    items,
    amountFormatter,
  })
);
