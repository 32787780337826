import { IToggleButton } from '@core-ui/toggle/types';
import SvgIcon from '@mui/material/SvgIcon';
import { ANALYTICS_EXPENSES_VIEW } from '@/pages/analytics/AnalyticsInner/consts';
import { ChartBar, ChartPie } from '@phosphor-icons/react';

export const viewToggleButtons: IToggleButton[] = [
  {
    value: ANALYTICS_EXPENSES_VIEW.MONTH,
    content: (
      <SvgIcon>
        <ChartBar />
      </SvgIcon>
    ),
  },
  {
    value: ANALYTICS_EXPENSES_VIEW.GENERAL,
    content: (
      <SvgIcon>
        <ChartPie />
      </SvgIcon>
    ),
  },
];
