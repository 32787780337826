import { useTableDynamicShadows } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import React, { useRef } from 'react';
import { getCostArticleMappingEnabled } from '@/app/selectors';
import { useAppSelector } from '@/hooks/useAppSelector';
import AnalyticsExpensesClientArticles from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesClientArticles';
import AnalyticsExpensesMyoceanArticles from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles';
import { ANALYTICS_EXPENSES_LIST_CONTAINER_ID } from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesRoot/AnalyticsExpensesRoot';
import useStyles from './styles';

const AnalyticsExpensesContent = () => {
  const costArticleMappingEnabled = useAppSelector(getCostArticleMappingEnabled);

  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const styles = useStyles();
  const applyRef = useTableDynamicShadows(ref);

  return (
    <Grid
      item
      container
      sx={styles.contentContainer}
      id={ANALYTICS_EXPENSES_LIST_CONTAINER_ID}
      ref={applyRef}
    >
      {costArticleMappingEnabled ? <AnalyticsExpensesClientArticles /> : <AnalyticsExpensesMyoceanArticles />}
    </Grid>
  );
};

export default AnalyticsExpensesContent;
