import { GET } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { vehicleIdSelector } from '@/app/selectors';
import { ClientArticlesPlanFactSchema } from '@/generated';
import { IAnalyticsFiltersState } from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/reducer';
import { getAnalyticsFilters } from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* getClientArticlesPlanFact() {
  try {
    const filters: IAnalyticsFiltersState = yield select(getAnalyticsFilters);
    const boatId: number = yield select(vehicleIdSelector);

    const url = `/budgets/${boatId}/client_cost_articles/plan_fact`;
    const query = {
      cost_center_ids: Array.isArray(filters.costCenters)
        ? filters.costCenters
        : filters.costCenters
        ? [filters.costCenters]
        : [],
      date_from: filters.dateFrom ? Number(filters.dateFrom) : undefined,
      date_to: filters.dateTo ? Number(filters.dateTo) : undefined,
    };

    const response: ClientArticlesPlanFactSchema = yield call(GET, url, query);

    yield put(
      actions.setClientArticlesPlanFact({
        value: response,
        hasData: Boolean(response.cost_articles),
      })
    );
  } catch (e) {
    yield call(responseError, e);
    yield put(
      actions.setClientArticlesPlanFact({
        error: e as Error,
        hasData: false,
      })
    );
  }
}

export default [takeLatest(actions.getClientArticlesPlanFact, getClientArticlesPlanFact)];
