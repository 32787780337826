import { useAppSelector } from '@/hooks/useAppSelector';
import TableBody from '@mui/material/TableBody';
import React from 'react';
import { AnalyticsPlanFactClientArticlesTableRow, AnalyticsPlanFactClientArticlesTableSection } from '..';
import selector from './selector';

const AnalyticsPlanFactClientArticlesTableBody = () => {
  const { isSections, items, itemsBySections, boatId, selectedMonths, filters, amountFormatter } =
    useAppSelector(selector);

  return (
    <TableBody>
      {isSections ? (
        <>
          {itemsBySections?.map((section) => (
            <AnalyticsPlanFactClientArticlesTableSection
              key={section.section_name}
              section={section}
              boatId={boatId}
              monthInterval={selectedMonths}
              dateFrom={filters.dateFrom}
              dateTo={filters.dateTo}
              amountFormatter={amountFormatter}
            />
          ))}
        </>
      ) : (
        <>
          {items?.map((costArticle) => (
            <AnalyticsPlanFactClientArticlesTableRow
              key={costArticle.cost_article_clients_code}
              costArticle={costArticle}
              boatId={boatId}
              monthInterval={selectedMonths}
              dateFrom={filters.dateFrom}
              dateTo={filters.dateTo}
              amountFormatter={amountFormatter}
            />
          ))}
        </>
      )}
    </TableBody>
  );
};

export default AnalyticsPlanFactClientArticlesTableBody;
