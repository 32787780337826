import { HeaderCell, TableRow } from '@core-ui/table';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import { useAppSelector } from '@/hooks/useAppSelector';
import React from 'react';
import selector from './selector';

const AnalyticsExpensesClientArticlesGeneralTableHeader = () => {
  const headers = useAppSelector(selector);

  return (
    <TableHead>
      <TableRow>
        {headers.map((column) => (
          <HeaderCell key={column.key} align={column.align}>
            <Box>{column.title}</Box>
          </HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default AnalyticsExpensesClientArticlesGeneralTableHeader;
