import { vehicleIdSelector } from '@/app/selectors';
import { getAmountFormatter } from '@/dictionary/selector';
import {
  getAnalyticsFilters,
  getAnalyticsFiltersSelectedMonths,
} from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { getAnalyticsPlanFactClientArticlesValue } from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactRoot/selector';
import isNil from 'lodash-es/isNil';
import { createSelector } from 'reselect';

export default createSelector(
  [
    getAnalyticsPlanFactClientArticlesValue,
    vehicleIdSelector,
    getAnalyticsFilters,
    getAnalyticsFiltersSelectedMonths,
    getAmountFormatter,
  ],
  (planFactValue, boatId, filters, selectedMonths, amountFormatter) => {
    const isSections =
      !isNil(planFactValue?.cost_articles_by_sections?.length) && planFactValue!.cost_articles_by_sections!.length > 0;

    return {
      isSections,
      items: planFactValue?.cost_articles,
      itemsBySections: planFactValue?.cost_articles_by_sections,
      boatId,
      filters,
      selectedMonths,
      amountFormatter,
    };
  }
);
