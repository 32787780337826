import { extractFlattenPaths, Route } from '@core-ui/redux-router';
import { IRouteParams, ROUTES } from '@/app/consts/routes';
import State from '@/app/types/state';
import { ANALYTICS_TABS, ANALYTICS_TABS_PATH } from '@/pages/analytics/consts';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import analyticsInnerRoutes from './AnalyticsInner/routes';
import AnalyticsRoot from './AnalyticsRoot';

const key: keyof State = 'analytics';

export const ANALYTICS_ROUTE: Route = {
  key,
  path: `/:boatId([0-9]+)/:route(${ROUTES.ANALYTICS})/:analyticsTab(${ANALYTICS_TABS_PATH})`,
  component: AnalyticsRoot,
  routes: [...analyticsInnerRoutes],
};

const routes: Route[] = [
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.ANALYTICS})`,
    component: (props: RouteComponentProps<IRouteParams>) => (
      <Redirect to={`${props.location.pathname}/${ANALYTICS_TABS.ANALYTICS}`} />
    ),
  },
  ANALYTICS_ROUTE,
];

export const ANALYTICS_PATHS = extractFlattenPaths(routes);

export default routes;
