export const ANALYTICS_FILTERS_HEIGHT = 72;
export const ANALYTICS_INNER_TABS_HEIGHT = 64;
export const ANALYTICS_EXPENSES_CONTENT_HEADER_HEIGHT = 76;
export const ANALYTICS_PLAN_FACT_CONTENT_HEADER_HEIGHT = 92;
export const ANALYTICS_GRAPH_HEIGHT = 310;
export const DEFAULT_ROW_HEIGHT = 50;

export enum ANALYTICS_INNER_TABS {
  EXPENSES = 'expenses',
  PLAN_FACT = 'plan_fact',
}

export const ANALYTICS_INNER_TABS_PATH = Object.values(ANALYTICS_INNER_TABS).join('|');

export const ANALYTICS_VIEW_QUERY_KEY = 'view';

export enum ANALYTICS_EXPENSES_VIEW {
  GENERAL = 'general',
  MONTH = 'month',
}
