import { COLOURS } from '@core-ui/styles';
import { buttonBaseClasses, tableCellClasses, tableRowClasses } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

export default (isRootArticle?: boolean, isFirstRow?: boolean): Record<string, SxProps<Theme>> => ({
  row: isRootArticle
    ? {
        [`&.${tableRowClasses.root}:nth-of-type(n)>.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
          backgroundColor: COLOURS.Coconut.Solid[100],
          borderTop: !isFirstRow ? `2px solid ${COLOURS.Coconut.Solid[300]}` : undefined,
        },
      }
    : {},

  nameCell: isRootArticle
    ? {
        [`&.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
          paddingY: 2,
          height: '38px',
          minHeight: '38px',
        },
      }
    : {
        [`&.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
          paddingLeft: 6,
        },
      },

  totalCell: isRootArticle
    ? {
        [`&.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
          paddingY: 2,
          height: '38px',
          minHeight: '38px',
        },
      }
    : {},

  openInLedgerButton: {
    [`&.${buttonBaseClasses.root}`]: {
      padding: 0,
    },
  },
});
