import { createSelector } from 'reselect';
import { shouldShowRub } from '@/app/selectors';
import { getAmountFormatter } from '@/dictionary/selector';
import { getAnalyticsMyOceanArticlesTotal } from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/selector';

export default createSelector(
  [getAnalyticsMyOceanArticlesTotal, getAmountFormatter, shouldShowRub],
  (analyticsTotal, amountFormatter, showRub) => {
    const formattedTotal = amountFormatter(analyticsTotal);

    return {
      formattedTotal,
      showRub,
    };
  }
);
