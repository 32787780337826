import { shouldShowRub } from '@/app/selectors';
import {
  getAnalyticsFiltersSelectedMonths,
  getCurrentYearSelected,
} from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { removeYearSuffixFromMonthName } from 'pages/analytics/AnalyticsInner/utils';
import { createSelector } from 'reselect';

export default createSelector(
  [getAnalyticsFiltersSelectedMonths, shouldShowRub, getCurrentYearSelected],
  (monthHeaders, showRub, isCurrentYearSelected) => ({
    monthHeaders: isCurrentYearSelected
      ? monthHeaders.map((month) => removeYearSuffixFromMonthName(month))
      : monthHeaders,
    showRub,
  })
);
