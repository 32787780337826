import { getCostArticleMappingEnabled } from '@/app/selectors';
import { NoDataContainerSelector } from '@/components/NoDataContainer/NoDataContainer';
import { getAnalyticsClientArticlesList } from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesClientArticles/selector';
import { getAnalyticsMyoceanArticlesList } from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/selector';
import { createSelector } from 'reselect';

export const noDataSelector: NoDataContainerSelector = createSelector(
  [getCostArticleMappingEnabled, getAnalyticsMyoceanArticlesList, getAnalyticsClientArticlesList],
  (mappingEnabled, myoceanArticlesAnalyticsList, clientArticlesAnalyticsList) => {
    const listState = mappingEnabled ? clientArticlesAnalyticsList : myoceanArticlesAnalyticsList;

    return {
      loading: listState.loading,
      loaded: listState.loaded,
      hasData: listState.hasData,
      error: listState.error,
    };
  }
);
