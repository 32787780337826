import { SHOW_HIDE_SHADOW_KEYFRAMES, TRANSITION_TIMING_FUNCTION } from '@core-ui/styles';
import { tableCellClasses } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import {
  PLAN_FACT_TABLE_HEADER_CELL_HEIGHT,
  TABLE_SECTION_DYNAMIC_BOTTOM_SHADOW_HIDE,
  TABLE_SECTION_DYNAMIC_BOTTOM_SHADOW_SHOW,
} from 'pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/components/AnalyticsPlanFactClientArticlesTable/consts';

export default (): Record<string, SxProps<Theme>> => ({
  sectionHeadRow: {
    position: 'sticky',
    top: PLAN_FACT_TABLE_HEADER_CELL_HEIGHT * 2,
    zIndex: 3,

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      pointerEvents: 'none',
      touchEvent: 'none',
      boxShadow: 'rgba(0, 0, 0, 0.15) 0px 4px 4px 0px',

      opacity: 0,
    },

    [`&.${TABLE_SECTION_DYNAMIC_BOTTOM_SHADOW_SHOW}:after`]: {
      animation: `showShadow .3s ${TRANSITION_TIMING_FUNCTION} forwards`,
    },

    [`&.${TABLE_SECTION_DYNAMIC_BOTTOM_SHADOW_HIDE}:after`]: {
      animation: `hideShadow .3s ${TRANSITION_TIMING_FUNCTION} forwards`,
    },

    ...SHOW_HIDE_SHADOW_KEYFRAMES,
  },

  sectionNameCell: {
    [`&.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
      position: 'sticky',
      left: 0,
    },
  },
});
