import { SxProps, Theme } from '@mui/material/styles';
import {
  TABLE_MIN_MONTH_CELL_WIDTH,
  TABLE_MIN_NAME_CELL_WIDTH,
  TABLE_MIN_TOTAL_CELL_WIDTH,
} from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesClientArticles/components/AnalyticsExpensesClientArticlesMonthTable/consts';

export default (): Record<string, SxProps<Theme>> => ({
  nameCell: {
    width: TABLE_MIN_NAME_CELL_WIDTH,
    minWidth: TABLE_MIN_NAME_CELL_WIDTH,
    maxWidth: TABLE_MIN_NAME_CELL_WIDTH,
    left: 0,
    zIndex: 3,
  },

  totalCell: {
    width: TABLE_MIN_TOTAL_CELL_WIDTH,
    minWidth: TABLE_MIN_TOTAL_CELL_WIDTH,
    maxWidth: TABLE_MIN_TOTAL_CELL_WIDTH,
    left: TABLE_MIN_NAME_CELL_WIDTH,
    textAlign: 'right',
    zIndex: 3,
  },

  monthCell: {
    width: TABLE_MIN_MONTH_CELL_WIDTH,
    minWidth: TABLE_MIN_MONTH_CELL_WIDTH,
  },
});
