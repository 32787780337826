import { ITableColumn } from '@/pages/backoffice/types';
import { FormattedMessage } from 'components/Intl';

export const COMMON_HEADERS: ITableColumn[] = [
  {
    key: 'name',
    title: <FormattedMessage id="table.header.name" />,
    width: '5fr',
  },
];

// TODO: временное решение, переделаем когда бу дет завозить exchange rates
export const EUR_TOTAL_HEADER: ITableColumn = {
  key: 'total_eur',
  title: <FormattedMessage id="table.header.ca_total_eur" />,
  align: 'right',
  width: '1fr',
};

export const RUB_TOTAL_HEADER: ITableColumn = {
  key: 'total_rub',
  title: <FormattedMessage id="table.header.ca_total_rub" />,
  align: 'right',
  width: '1fr',
};
