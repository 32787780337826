import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '@/hooks/useAppSelector';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import selector from './selector';

const AnalyticsPlanFactHeader = () => {
  const { plannedTotal, factTotal, showRub, amountFormatter } = useAppSelector(selector);

  return (
    <Grid item container justifyContent="space-between" wrap="nowrap" padding={6}>
      <Grid item container width="auto">
        <Typography variant="h3">
          <FormattedMessage id="analytics.tabs.plan_vs_fact" />
        </Typography>
      </Grid>

      <Grid item container gap={4} width="auto" wrap="nowrap">
        <Grid item container direction="column" gap={1} width="auto">
          <Typography color="text.secondary" fontSize="small" textTransform="uppercase" align="right" noWrap>
            <FormattedMessage id="analytics.plan_fact.planned_expenses" />
          </Typography>

          <Typography fontSize="large" fontWeight="semiBold" align="right">
            {amountFormatter(plannedTotal)}
          </Typography>
        </Grid>

        <Divider orientation="vertical" />

        <Grid item container direction="column" gap={1} width="auto">
          <Typography color="text.secondary" fontSize="small" textTransform="uppercase" align="right" noWrap>
            <FormattedMessage
              id={showRub ? 'analytics.plan_fact.total_expenses.rub' : 'analytics.plan_fact.total_expenses.eur'}
            />
          </Typography>

          <Typography fontSize="large" fontWeight="semiBold" align="right">
            {amountFormatter(factTotal)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AnalyticsPlanFactHeader;
