import { MySkyLoader } from '@core-ui/icons';
import Grid from '@mui/material/Grid';
import { router } from '@/configureStore';
import AnalyticsFilters from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/AnalyticsFilters';
import AnalyticsInnerTabs from '@/pages/analytics/AnalyticsInner/AnalyticsInnerTabs';
import { ANALYTICS_INNER_ROUTE } from '@/pages/analytics/AnalyticsInner/routes';
import React from 'react';
import useStyles from './styles';

// TODO: дурацкое название, но бизнес пока не придумал как разделить названия раздела и подраздела
//  по этой же причине названия компонентов фильтров, экспенсов и т.д. пока что будет просто AnalyticsExpenses, AnalyticsFilters и т.д.
const AnalyticsInnerRoot = () => {
  const styles = useStyles();

  return (
    <Grid container direction="column" wrap="nowrap" flex={1} sx={styles.container}>
      <AnalyticsFilters />
      <AnalyticsInnerTabs />

      <Grid item container wrap="nowrap" sx={styles.routesContainer}>
        {ANALYTICS_INNER_ROUTE.routes &&
          router.render(ANALYTICS_INNER_ROUTE.routes, <MySkyLoader style={styles.fallback} />)}
      </Grid>
    </Grid>
  );
};

export default AnalyticsInnerRoot;
