import { getList, resetState, setList, setLoading } from '@core-ui/reducers_handlers';
import { IFormattedCostArticleResponseSchema } from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/types';

export const reducerName = 'analytics.expenses.myocean_articles.list';

export const getMyoceanArticlesAnalyticsExpensesList = getList(reducerName);
export const setMyoceanArticlesAnalyticsExpensesList = setList<IFormattedCostArticleResponseSchema>(reducerName);

export const setMyoceanArticlesAnalyticsExpensesLoading = setLoading(reducerName);

export const resetMyoceanArticlesAnalyticsExpensesList = resetState(reducerName);
