import Button from '@core-ui/button';
import NoDataPlaceholder from '@/components/NoDataPlaceholder';
import { turnOnCostArticlesMappingFromAnalyticsPlanFact } from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactRoot/actions';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';

const TurnOnClientCostArticlesPlaceholder = () => {
  const dispatch = useDispatch();

  const handleTurnOnAndReload = () => {
    dispatch(turnOnCostArticlesMappingFromAnalyticsPlanFact());
  };

  const button = (
    <Button
      color="blue"
      variant="contained"
      label={<FormattedMessage id="analytics.turn_on_and_reload_placeholder.button" />}
      onClick={handleTurnOnAndReload}
    />
  );

  return (
    <NoDataPlaceholder
      titleId="analytics.turn_on_and_reload_placeholder.title"
      subtitleId="analytics.turn_on_and_reload_placeholder.subtitle"
      extraElement={button}
      dataTestId="analytics_page-plan_fact-turn_on_mapping_placeholder"
    />
  );
};

export default TurnOnClientCostArticlesPlaceholder;
